@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/montserrat_300.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/montserrat_400.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/montserrat_400i.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/montserrat_700.woff") format("woff");
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/merriweather_400i.woff") format("woff");
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/merriweather_700.woff") format("woff");
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
}

nav {
  display: block;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #648B4F;
  text-decoration: none;
  background-color: transparent;
  transition: color 0.3s, background-color 0.3s, opacity 0.3s;
}
a:hover {
  color: rgb(64.9082568807, 90.2224770642, 51.2775229358);
  text-decoration: underline;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

h1, h2, .h1, .h3 {
  margin-bottom: 0.5rem;
  font-family: "Merriweather", sans-serif;
  font-weight: 700;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

.h3 {
  font-size: 1.75rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.container-fluid, .container-fluid-md, .container-fluid-lg, .container-fluid-xl, .container-fluid-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-fluid, .container-fluid-md, .container-fluid-lg, .container-fluid-xl, .container-fluid-xxl {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.container-fluid-md {
  max-width: 720px;
}

.container-fluid-lg {
  max-width: 960px;
}

.container-fluid-xl {
  max-width: 1140px;
}

.container-fluid-xxl {
  max-width: 1340px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.row > .col,
.row > [class*=col-] {
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 992px) {
  .row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .row > .col,
  .row > [class*=col-] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.small-gutters {
  margin-right: -15px;
  margin-left: -15px;
}
.small-gutters > .col,
.small-gutters > [class*=col-] {
  padding-right: 15px;
  padding-left: 15px;
}

.col-xl-auto, .col-xl, .col-xl-12, .col-xl-3, .col-lg-auto, .col-lg, .col-lg-12, .col-lg-6, .col-lg-4, .col-md-auto, .col-md, .col-md-12, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-sm-auto, .col-sm, .col-sm-8, .col-sm-6, .col-sm-4, .col-sm-3, .col-auto, .col, .col-12, .col-8, .col-6, .col-5, .col-4, .col-3 {
  position: relative;
  width: 100%;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #F2F2F2;
  background-clip: padding-box;
  border: 1px solid #BFBFBF;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #F2F2F2;
  border-color: rgb(165.9587155963, 195.3876146789, 150.1123853211);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(100, 139, 79, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control:-webkit-autofill, .form-control:-webkit-autofill:hover, .form-control:-webkit-autofill:focus, .form-control:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #495057 !important;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #F2F2F2;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #F2F2F2 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: rgb(227.5316455696, 96.4683544304, 109.0253164557);
  background-color: rgb(227.5316455696, 96.4683544304, 109.0253164557);
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #C28E40;
  background-color: #C28E40;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(194, 142, 64, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgb(165.9587155963, 195.3876146789, 150.1123853211);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: rgb(193.5642201835, 213.869266055, 182.630733945);
  border-color: rgb(193.5642201835, 213.869266055, 182.630733945);
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  cursor: pointer;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #F2F2F2;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #648B4F;
  background-color: #648B4F;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(100, 139, 79, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(100, 139, 79, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(100, 139, 79, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #F2F2F2 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #BFBFBF;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: rgb(165.9587155963, 195.3876146789, 150.1123853211);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(100, 139, 79, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #F2F2F2;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-control-label::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before {
    transition: none;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
}

.btn-primary {
  color: #fff;
  background: #648B4F;
  border-color: #648B4F;
}
.btn-primary:hover {
  color: #fff;
  background: rgb(88.3027522936, 122.7408256881, 69.7591743119);
  border-color: rgb(88.3027522936, 122.7408256881, 69.7591743119);
}

.btn-secondary {
  color: #fff;
  background: #C28E40;
  border-color: #C28E40;
}
.btn-secondary:hover {
  color: #fff;
  background: rgb(176.2202380952, 128.244047619, 56.2797619048);
  border-color: rgb(176.2202380952, 128.244047619, 56.2797619048);
}

.btn-light {
  color: #212529;
  background: #fff;
  border-color: #fff;
}
.btn-light:hover {
  color: #212529;
  background: rgb(242.25, 242.25, 242.25);
  border-color: rgb(242.25, 242.25, 242.25);
}

.btn-outline-primary {
  color: #648B4F;
  border-color: #648B4F;
}
.btn-outline-primary:hover, .btn-outline-primary.active {
  color: #fff;
  background: #648B4F;
}

.btn-outline-secondary {
  color: #C28E40;
  border-color: #C28E40;
}
.btn-outline-secondary:hover, .btn-outline-secondary.active {
  color: #fff;
  background: #C28E40;
}

.btn-outline-light {
  color: #fff;
  border-color: #fff;
}
.btn-outline-light:hover {
  color: #212529;
  background: #fff;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-danger {
  color: rgb(114.4, 27.56, 35.88);
  background-color: rgb(248, 214.6, 217.8);
  border-color: rgb(245.2, 198.44, 202.92);
}
.alert-danger hr {
  border-top-color: rgb(241.4341772152, 176.7058227848, 182.9073417722);
}
.alert-danger .alert-link {
  color: rgb(73.3010989011, 17.6589010989, 22.9898901099);
}

.alert-success {
  color: rgb(52, 72.28, 41.08);
  background-color: rgb(224, 231.8, 219.8);
  border-color: rgb(211.6, 222.52, 205.72);
}
.alert-success hr {
  border-top-color: rgb(198.0640410959, 212.3898630137, 190.3501369863);
}
.alert-success .alert-link {
  color: rgb(28.6055045872, 39.7616513761, 22.5983486239);
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-append {
  display: flex;
}
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #F2F2F2;
  border: 1px solid #BFBFBF;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #648B4F;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: rgb(64.9082568807, 90.2224770642, 51.2775229358);
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(100, 139, 79, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #648B4F;
  border-color: #648B4F;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

@keyframes carousel-forward {
  0% {
    transform: translateX(0%) translateZ(0);
  }
  100% {
    transform: translateX(-100%) translateZ(0);
  }
}
@keyframes carousel-backward {
  0% {
    transform: translateX(-100%) translateZ(0);
  }
  100% {
    transform: translateX(0) translateZ(0);
  }
}
@keyframes carousel-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.carousel-slides {
  display: flex;
  overflow: hidden;
}
.carousel-slides.autoplay .carousel-slide {
  animation-duration: 1.5s;
}

.carousel-slide, .carousel-fade {
  width: 100%;
  flex: 0 0 100%;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.carousel-slide.forward {
  animation-name: carousel-forward;
  transform: translateX(-100%) translateZ(0);
}
.carousel-slide.backward {
  animation-name: carousel-backward;
  transform: translateX(0) translateZ(0);
}

.carousel-fade {
  opacity: 0;
  position: relative;
}
.carousel-fade.active {
  opacity: 1;
}
.carousel-fade.active.forward, .carousel-fade.active.backward {
  z-index: 2;
  animation-name: carousel-fade-in;
}
.carousel-fade.active.forward, .carousel-fade.next.backward {
  transform: translateX(-100%) translateZ(0);
}
.carousel-fade.last.forward, .carousel-fade.next.backward {
  opacity: 1;
  z-index: 1;
}

.slider-slides {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  overflow: auto;
  scroll-snap-type: both mandatory;
  overscroll-behavior: none auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.slider-slides::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.slider-slide {
  flex-grow: 0;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.slider-slides-vertical {
  flex-direction: column;
  overscroll-behavior: auto none;
}

.slider-slide {
  width: 100%;
}

.slider-prev, .slider-next {
  border: none;
  background: transparent;
  outline: none;
  margin: 0;
  padding: 0;
}

body.modal-opened {
  overscroll-behavior-y: none;
  overflow: hidden;
}

@keyframes modal-sending-request-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes modal-open-modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 99999;
  opacity: 0;
  transition: opacity 0.2s;
  overscroll-behavior-y: none;
}
.modal.opened {
  display: block;
  opacity: 1;
  animation: modal-open-modal 0.5s forwards;
}

.modal.sending-request::before, .modal-sending-request::before {
  content: "";
  position: absolute;
  display: block;
  width: 80px;
  height: 80px;
  position: absolute;
  margin: -40px 0 0 -40px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: 5px solid #fff;
  border-right-color: transparent;
  animation: modal-sending-request-spin 2s infinite linear;
}

.modal-btn {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  line-height: 1;
}
.modal-btn.disabled {
  display: none;
}

.modal-center-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.modal-center-right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.modal-top-right {
  top: 0;
  right: 0;
}

.modal-bottom-right {
  bottom: 0;
  right: 0;
}

.modal-icon-cross::before {
  content: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8" standalone="no"%3F%3E%3C!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"%3E%3Csvg width="15px" height="15px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"%3E%3Cpath d="M0.314,1.829L5.985,7.5L0.314,13.171C-0.105,13.59 -0.105,14.268 0.314,14.686C0.733,15.104 1.411,15.105 1.829,14.686L7.5,9.015L13.171,14.686C13.59,15.105 14.268,15.105 14.686,14.686C15.104,14.267 15.105,13.589 14.686,13.171L9.015,7.5L14.686,1.829C15.105,1.41 15.105,0.732 14.686,0.314C14.267,-0.104 13.589,-0.105 13.171,0.314L7.5,5.985L1.829,0.314C1.41,-0.105 0.732,-0.105 0.314,0.314C-0.104,0.733 -0.105,1.411 0.314,1.829Z" style="fill:%23aaa;fill-rule:nonzero;"/%3E%3C/svg%3E');
}

.modal-icon-arrow-right::before {
  content: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8" standalone="no"%3F%3E%3C!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"%3E%3Csvg width="15px" height="15px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"%3E%3Cpath d="M5.043,14.686L11.472,8.258C11.891,7.839 11.891,7.161 11.472,6.743L5.043,0.314C4.624,-0.105 3.946,-0.105 3.528,0.314C3.11,0.733 3.109,1.411 3.528,1.829L9.199,7.5L3.528,13.171C3.109,13.59 3.109,14.268 3.528,14.686C3.947,15.104 4.625,15.105 5.043,14.686Z" style="fill:%23aaa;fill-rule:nonzero;"/%3E%3C/svg%3E');
}

.modal-icon-arrow-left::before {
  content: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8" standalone="no"%3F%3E%3C!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"%3E%3Csvg width="15px" height="15px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"%3E%3Cpath d="M9.957,14.686L3.528,8.258C3.109,7.839 3.109,7.161 3.528,6.743L9.957,0.314C10.376,-0.105 11.054,-0.105 11.472,0.314C11.89,0.733 11.891,1.411 11.472,1.829L5.801,7.5L11.472,13.171C11.891,13.59 11.891,14.268 11.472,14.686C11.053,15.104 10.375,15.105 9.957,14.686Z" style="fill:%23aaa;fill-rule:nonzero;"/%3E%3C/svg%3E');
}

.modal-dialog {
  margin: 15px auto;
  padding-left: 7.5px;
  padding-right: 7.5px;
  min-height: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.modal-content {
  position: relative;
  color: #212529;
  background: #fff;
  margin: 0 auto;
  max-width: 800px;
}

.modal-dialog-danger .modal-content {
  border: 2px solid #dc3545;
}

.modal-sm .modal-content {
  width: 100%;
  max-width: 540px;
}

.modal-md .modal-content {
  width: 100%;
  max-width: 720px;
}

.modal-lg .modal-content {
  width: 100%;
  max-width: 960px;
}

.modal-dialog-top {
  padding: 1rem;
  text-align: right;
}

.modal-dialog-body {
  padding: 1rem;
}

.modal-dialog-bottom {
  text-align: center;
  padding: 0 1rem 1rem;
}

@media (min-width: 576px) {
  .modal-dialog-top,
  .modal-dialog-body {
    padding: 2rem;
  }
  .modal-dialog-top + .modal-dialog-body {
    padding-top: 0;
  }
  .modal-dialog-bottom {
    padding: 0 2rem 2rem;
  }
}
.modal-gallery {
  background: rgba(0, 0, 0, 0.9);
}

.modal-gallery-inner {
  user-select: none;
  height: 100%;
}

.modal-gallery-btn {
  padding: 1rem;
  transition: color 0.3s, background 0.3s;
  color: #aaa;
  background: rgba(0, 0, 0, 0.3);
  font-size: 1.5rem;
  z-index: 5;
}
.modal-gallery-btn:hover {
  color: #fff;
  background: black;
}

@keyframes modal-gallery-from-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes modal-gallery-from-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes modal-gallery-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.modal-gallery-content {
  height: 100%;
  padding: 1rem;
}
.modal-gallery-content.has-navigation {
  height: calc(100% - 60px);
}

.modal-gallery-slides {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.modal-gallery-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}
.modal-gallery-slide.forward {
  animation-name: modal-gallery-from-right;
}
.modal-gallery-slide.backward {
  animation-name: modal-gallery-from-left;
}
.modal-gallery-slide.last.forward, .modal-gallery-slide.next.backward {
  animation-name: modal-gallery-fade-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
.modal-gallery-slide.active {
  visibility: visible;
  opacity: 1;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
}

.modal-gallery-slide img, .modal-gallery-slide video, .modal-gallery-slide iframe {
  max-width: 100%;
  max-height: 100%;
}
.modal-gallery-slide iframe {
  background: #000;
}

.modal-gallery-caption {
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  font-size: 13px;
  padding: 0.5rem 1rem;
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  max-width: 100%;
  transform: translateX(-50%);
  cursor: pointer;
}

.modal-gallery-nav {
  display: flex;
  height: 60px;
  padding: 2px 1rem 1rem;
  overflow: auto;
  scroll-snap-type: both mandatory;
  overscroll-behavior: none auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.modal-gallery-nav::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.modal-gallery-nav img {
  width: auto;
  height: 100%;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  border: 2px solid transparent;
  cursor: pointer;
}
.modal-gallery-nav img:hover {
  outline: 2px solid #aaa;
}
.modal-gallery-nav img.active {
  outline: 2px solid #fff;
}
.modal-gallery-nav :first-child {
  margin-left: auto;
}
.modal-gallery-nav :last-child {
  margin-right: auto;
}

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
  width: auto;
  padding: 0;
}

.leaflet-container img.leaflet-tile {
  /* See: https://bugs.chromium.org/p/chromium/issues/detail?id=600120 */
  mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */
.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline-offset: 1px;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5;
}

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover,
.leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
  font-size: 13px;
  font-size: 1.08333em;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path { /* used only in path-guessing heuristic, see L.Icon.Default */
  background-image: url(images/marker-icon.png);
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover,
.leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  display: inline !important;
  vertical-align: baseline !important;
  width: 1em;
  height: 0.6669em;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  white-space: nowrap;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px #fff;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 24px 13px 20px;
  line-height: 1.3;
  font-size: 13px;
  font-size: 1.08333em;
  min-height: 1px;
}

.leaflet-popup-content p {
  margin: 17px 0;
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-top: -1px;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  pointer-events: auto;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  font: 16px/24px Tahoma, Verdana, sans-serif;
  color: #757575;
  text-decoration: none;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover,
.leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

/* Printing */
@media print {
  /* Prevent printers from removing background-images of controls. */
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
.link-nodecoration {
  color: inherit;
}
.link-nodecoration:hover {
  color: inherit;
  opacity: 0.8;
  text-decoration: none;
}

td.fit-width, th.fit-width {
  width: 1px;
}

table tr:first-child td, table tr:first-child th {
  border-top: 0;
}

.bt-1 {
  border-top: 1px solid #dee2e6;
}

.img-rounded {
  border-radius: 0.75rem;
}

.img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-cover-top {
  object-position: top center;
}

.img-rounded-top {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.img-rounded-left {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.modal-dialog-transparent .modal-content {
  background: none;
}

.expanded-180 {
  transition: transform 0.3s;
}

.expanded[data-slide] .expanded-180, .expanding[data-slide] .expanded-180 {
  transform: rotate(180deg);
}

.collapsing-d-none.collapsing {
  display: none !important;
}

.border-bottom {
  border-bottom: 1px solid #212529;
}

@media (min-width: 576px) {
  .img-rounded-sm-left {
    border-radius: 0;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
}
@media (min-width: 992px) {
  .rotate-lg-90 {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
  }
}
@font-face {
  font-family: "icomoon";
  src: url("/icomoon.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icm {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: 35%;
  display: inline-flex;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icm-lg {
  font-size: 1.25em;
  vertical-align: 30%;
}

.icm-2x {
  font-size: 1.75em;
}

.icm-3x {
  font-size: 2.75em;
}

.icm-4x {
  font-size: 3.75em;
}

.icm-round {
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
}

.icm-blot {
  position: relative;
}
.icm-blot::before {
  position: relative;
  z-index: 2;
}
.icm-blot::after {
  content: url("/blot.svg");
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 40%;
  transform: translateX(-50%);
}

.icm-star-half:before {
  content: "\e91d";
}

.icm-star-full:before {
  content: "\e91e";
}

.icm-star-empty:before {
  content: "\e91f";
}

.icm-check:before {
  content: "\e91c";
}

.icm-wallet:before {
  content: "\e917";
}

.icm-low-carb:before {
  content: "\e911";
}

.icm-carrot:before {
  content: "\e912";
}

.icm-heart:before {
  content: "\e913";
}

.icm-muscle:before {
  content: "\e914";
}

.icm-arrow-left:before {
  content: "\e900";
}

.icm-arrow-right:before {
  content: "\e901";
}

.icm-triangle-down:before {
  content: "\e902";
}

.icm-chevron-right:before {
  content: "\e918";
}

.icm-chevron-left:before {
  content: "\e919";
}

.icm-chevron-down:before {
  content: "\e903";
}

.icm-chevron-up:before {
  content: "\e904";
}

.icm-print:before {
  content: "\e91b";
}

.icm-eye:before {
  content: "\e91a";
}

.icm-bank:before {
  content: "\e915";
}

.icm-credit-card:before {
  content: "\e916";
}

.icm-times:before {
  content: "\e910";
}

.icm-bars:before {
  content: "\e90f";
}

.icm-wechat:before {
  content: "\e90c";
}

.icm-truck:before {
  content: "\e90d";
}

.icm-envelope-square:before {
  content: "\e90e";
}

.icm-envelope:before {
  content: "\e905";
}

.icm-clock-o:before {
  content: "\e906";
}

.icm-shopping-basket:before {
  content: "\e907";
}

.icm-instagram:before {
  content: "\e908";
}

.icm-facebook-square:before {
  content: "\e909";
}

.icm-phone-square:before {
  content: "\e90a";
}

.icm-mobile:before {
  content: "\e90b";
}

.page-top {
  padding-top: 0.5rem;
}

.page-header {
  position: relative;
  color: #fff;
  background: #000;
}
.page-header .link-nodecoration:hover {
  color: #C28E40;
}

.page-header-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.page-header-fg {
  position: relative;
  padding-bottom: 4.5rem;
}

.page-hamburger {
  display: inline-block;
  margin-top: 28px;
}

.page-navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 8888;
  background: #000;
  padding: 15px;
}

.page-navigation-items {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-navigation-item {
  margin-top: 1rem;
}
.page-navigation-item a {
  text-transform: uppercase;
  font-weight: 300;
}

.page-boxes {
  background: #DFDFDF;
  padding-bottom: 0.5rem;
}
.page-boxes .container-fluid-xxl {
  margin-top: -4.5rem;
}

.homepage-product .container-fluid-xxl {
  position: relative;
  margin-top: -4.5rem;
}

.page-box {
  position: relative;
  display: block;
  max-width: 200px;
  margin: 0 auto;
}
.page-box:hover .page-box-title {
  opacity: 0;
}
.page-box:hover .page-box-btn {
  opacity: 1;
}

.page-box-btn, .page-box-title {
  position: absolute;
  top: 42.5%;
  left: 8.03986711%;
  right: 8.03986711%;
  transform: translateY(-50%);
  text-align: center;
  transition: opacity 0.3s;
}

.page-box-title {
  color: #fff;
  padding: 6% 0;
  text-transform: uppercase;
  font-weight: 700;
  opacity: 1;
}

.page-box-btn {
  opacity: 0;
}

@media (min-width: 992px) {
  .page-logo {
    display: block;
    margin-top: 0.75rem;
  }
  .page-logo img {
    width: 240px;
    height: auto;
  }
  .page-header-fg {
    padding-top: 0.5rem;
    padding-bottom: 8rem;
  }
  .page-top {
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  .page-navigation {
    display: block !important;
    background: none;
    position: static;
    padding: 0;
  }
  .page-navigation-items {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .page-navigation-item {
    margin: 0;
  }
  .page-navigation-item + .page-navigation-item {
    margin-left: 1.5rem;
  }
  .page-boxes .container-fluid-xxl {
    margin-top: -7rem;
  }
}
@media (min-width: 992px) {
  .page-box {
    max-width: 260px;
  }
  .page-box-title {
    letter-spacing: 0.1em;
  }
  .page-box-title .icm {
    font-size: 2em;
    vertical-align: middle;
  }
}
@media (min-width: 1200px) {
  .page-logo img {
    width: 330px;
  }
  .page-top {
    margin-bottom: 1.75rem;
    font-size: 1rem;
  }
  .page-navigation-item a {
    font-size: 1.25rem;
  }
  .page-box-title {
    font-size: 1.25rem;
  }
}
.page-footer {
  color: #F4EEC8;
  background: #3C4B32;
  padding: 2rem 0;
  font-size: 0.9375rem;
}

.page-footer-logo img {
  display: inline-block;
  width: 290px;
  height: auto;
}

.page-footer-motto {
  color: #fff;
  font-size: 0.8125rem;
  text-transform: uppercase;
}

.page-footer-h1 {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.05em;
  margin-bottom: 1rem;
}

.page-footer-title {
  color: #fff;
}

.page-footer-navigation {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.page-footer-navigation li + li {
  margin-left: 1.5rem;
}

.page-footer-navigation-link {
  color: #fff;
  font-weight: 300;
  font-size: 1.125rem;
  text-transform: uppercase;
  letter-spacing: -0.05em;
}
.page-footer-navigation-link:hover {
  color: #F4EEC8;
  text-decoration: none;
}

.page-footer-link-color {
  color: #B1C0A4;
}

.page-footer-link {
  color: #B1C0A4;
  text-decoration: underline;
  font-style: italic;
}
.page-footer-link:hover {
  color: rgb(125.3506493506, 150.0909090909, 103.9090909091);
}

.page-copyright {
  color: #B1C0A4;
  background: #2E322D;
  font-size: 0.9375rem;
  letter-spacing: 0.1em;
  text-align: center;
  padding: 0.5rem 0;
}

@media (min-width: 1400px) {
  .page-footer-logo img {
    width: 350px;
  }
  .page-footer-motto {
    font-size: 1rem;
  }
}
.section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.section-xs {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.section-sm {
  padding-top: calc(2rem + 1 * ((100vw - 20rem) / 67.5));
}

.section-dark {
  color: #fff;
  background: #648B4F;
}
.section-dark .invalid-feedback {
  color: #fff;
}

.section-light {
  background: #E6E6E6;
}

.section-lightest {
  background: rgb(243.380952381, 233.4761904762, 218.619047619);
}

.section-h1 h1, .section-h1 h2, .section-h1 h3, .section-h2 h1, .section-h2 h2, .section-h2 h3, .section-h3 h1, .section-h3 h2, .section-h3 h3 {
  margin-bottom: 0;
}
.section-h1 h1::after, .section-h1 h2::after, .section-h1 h3::after, .section-h2 h1::after, .section-h2 h2::after, .section-h2 h3::after, .section-h3 h1::after, .section-h3 h2::after, .section-h3 h3::after {
  content: "";
  display: block;
  height: 4px;
  width: 50px;
  background: currentColor;
  margin-top: 1rem;
}

.section-h1, .section-h2 {
  margin-bottom: calc(1.5rem + 1 * ((100vw - 20rem) / 67.5));
}
.section-h1 h1, .section-h1 h2, .section-h2 h1, .section-h2 h2 {
  font-size: calc(1.75rem + 1.25 * ((100vw - 20rem) / 67.5));
}
.section-h1 p, .section-h2 p {
  text-transform: uppercase;
  margin: 1rem 0 0;
}

.section-h2 {
  text-align: center;
}
.section-h2 h2::after {
  margin-left: auto;
  margin-right: auto;
}

.section-h3 {
  margin-bottom: 3rem;
}
.section-h3 h1, .section-h3 h2 {
  font-size: calc(1.25rem + 0.5 * ((100vw - 20rem) / 67.5));
}

.card {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}

@media (min-width: 1400px) {
  .section-sm {
    padding-top: 3rem;
  }
  .section-h1, .section-h2 {
    margin-bottom: 2.5rem;
  }
  .section-h1 h2, .section-h2 h2 {
    font-size: 3rem;
  }
}
label {
  text-transform: uppercase;
}

@keyframes sending-request-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
form.sending-request button[type=submit] {
  position: relative;
}
form.sending-request button[type=submit]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  opacity: 0.8;
  border-radius: inherit;
}
form.sending-request button[type=submit]::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  border-left-color: transparent;
  animation: sending-request-spin 2s infinite linear;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
}

.btn-check-input {
  display: none;
}
.btn-check-input:checked ~ .btn-check-label .btn-check-icon, .btn-check-input:checked ~ .btn-check-label .btn-check-title {
  color: #fff;
  background: #C28E40 !important;
}

.btn-check-label {
  cursor: pointer;
  display: flex;
  text-transform: none;
  margin-bottom: 0;
  user-select: none;
}

.btn-check-icon {
  flex: 0 0 auto;
  color: #fff;
  background: #648B4F;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
}

.btn-check-title {
  flex: 1 1 100%;
  background: #D9D9D9;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
}

.btn-check-icon + .btn-check-title {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-container {
  max-width: 600px;
  margin: 0 auto;
}

.form-rating {
  user-select: none;
}
.form-rating label {
  margin: 0 0.25rem 0 0;
  color: #C28E40;
  cursor: pointer;
}
.form-rating :checked ~ label ~ label {
  color: #ddd;
}

.branches-map-country {
  transition: fill 0.3s;
  cursor: pointer;
  stroke: #b3b3b3;
  fill: #f2f2f2;
}
.branches-map-country.has-children {
  stroke: #807f7f;
  fill: rgb(152.1559633028, 186.1467889908, 133.8532110092);
}
.branches-map-country:hover {
  fill: #648B4F;
}

.branches-map-container {
  position: relative;
}

.branches-map {
  position: relative;
}

.branches-map-decoration {
  position: absolute;
  width: 70%;
  max-width: 500px;
  pointer-events: none;
  left: -20%;
  bottom: -4rem;
}

.branch-card {
  color: #fff;
  background: #648B4F;
  border-radius: 0.75rem;
  overflow: hidden;
}

.branch-card-body {
  padding: 2rem;
}

.branch-map {
  height: 100%;
  background: #fff;
}

@media (min-width: 576px) {
  .branches-map-decoration {
    bottom: -5.5rem;
  }
}
@media (min-width: 992px) {
  .branches-map-decoration {
    bottom: -4rem;
  }
}
a[data-gallery] {
  position: relative;
  display: inline-block;
}
a[data-gallery]::before, a[data-gallery]::after {
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
}
a[data-gallery]::before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
a[data-gallery]::after {
  content: "\e91a";
  font-family: "icomoon";
  font-size: 2rem;
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.4);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  border-radius: 50%;
  border: 5px solid transparent;
  box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.2), inset 0 0 0 2px rgba(255, 255, 255, 0.4);
}
a[data-gallery]:hover::before, a[data-gallery]:hover::after {
  opacity: 1;
}

.contact-us-section {
  color: #fff;
  background: #444 url("/bg_contact.webp") no-repeat center center;
  background-size: cover;
}
.contact-us-section .invalid-feedback {
  color: #fff;
}

.contact-us-card {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1.25rem;
  padding: 30px;
  height: 100%;
}

@media (min-width: 1200px) {
  .contact-us-card {
    padding-left: 45px;
    padding-right: 45px;
  }
}
.clip-content > *:last-child {
  margin-bottom: 0;
}

.homepage-carousel .carousel-slides.autoplay .carousel-slide {
  animation-duration: 1s;
}

.homepage-carousel-slide {
  padding-top: calc(2rem + 2 * ((100vw - 20rem) / 67.5));
  padding-bottom: calc(2rem + 3 * ((100vw - 20rem) / 67.5));
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
}

.homepage-carousel-h1 {
  font-family: "Merriweather", sans-serif;
  font-style: italic;
  font-size: calc(1.5rem + 2.25 * ((100vw - 20rem) / 67.5));
  margin-bottom: 2rem;
  letter-spacing: 0.05em;
}
.homepage-carousel-h1::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background: #fff;
  margin: 1rem auto 0;
}

.homepage-carousel-body {
  font-size: calc(1rem + 0.5 * ((100vw - 20rem) / 67.5));
  font-weight: 300;
}

.homepage-benefits-section {
  overflow: hidden;
}

.homepage-benefits-container {
  position: relative;
}
.homepage-benefits-container::before, .homepage-benefits-container::after {
  color: #6B9058;
  line-height: 1;
  font-size: 320px;
  letter-spacing: -0.1em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.homepage-benefits-container::before {
  content: "FF";
  right: 100%;
}
.homepage-benefits-container::after {
  content: "MENU";
  left: 100%;
}

.homepage-why-container {
  position: relative;
  margin-top: -1rem;
}

.homepage-why-col {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.homepage-why-item {
  line-height: 1.2;
  border-bottom: 1px solid #B4ACAC;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  text-transform: uppercase;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.homepage-why-number {
  color: #88A47A;
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.homepage-why-center, .homepage-why-image {
  position: absolute;
  top: calc(50% + 1rem);
  left: 50%;
  transform: translate(-50%, -50%);
}

.homepage-why-image {
  top: calc(50% + 2rem);
}

.homepage-why-image {
  width: 42%;
}

.homepage-why-line {
  position: absolute;
}

.homepage-why-line-1, .homepage-why-line-2 {
  background: linear-gradient(to bottom left, transparent, transparent 50%, #B4ACAC 50%, #B4ACAC calc(50% + 1px), transparent calc(50% + 1px), transparent);
}

.homepage-why-line-1, .homepage-why-line-2, .homepage-why-line-3, .homepage-why-line-4, .homepage-why-line-5 {
  left: calc(33.3333333333% - 45px);
  width: 25%;
}

.homepage-why-line-1, .homepage-why-line-6 {
  top: calc(20% - 1px);
  height: 20%;
}

.homepage-why-line-2, .homepage-why-line-7 {
  top: calc(40% - 1px);
  height: 10%;
}

.homepage-why-line-3, .homepage-why-line-8 {
  background: #B4ACAC;
  top: calc(60% - 1px);
  height: 1px;
}

.homepage-why-line-4, .homepage-why-line-5 {
  background: linear-gradient(to top left, transparent, transparent 50%, #B4ACAC 50%, #B4ACAC calc(50% + 1px), transparent calc(50% + 1px), transparent);
}

.homepage-why-line-4, .homepage-why-line-9 {
  top: 70%;
  height: 10%;
}

.homepage-why-line-5, .homepage-why-line-10 {
  top: 80%;
  height: 20%;
}

.homepage-why-line-6, .homepage-why-line-7 {
  background: linear-gradient(to bottom right, transparent, transparent 50%, #B4ACAC 50%, #B4ACAC calc(50% + 1px), transparent calc(50% + 1px), transparent);
}

.homepage-why-line-6, .homepage-why-line-7, .homepage-why-line-8, .homepage-why-line-9, .homepage-why-line-10 {
  right: calc(33.3333333333% - 45px);
  width: 25%;
}

.homepage-why-line-9, .homepage-why-line-10 {
  background: linear-gradient(to top right, transparent, transparent 50%, #B4ACAC 50%, #B4ACAC calc(50% + 1px), transparent calc(50% + 1px), transparent);
}

@media (min-width: 1400px) {
  .homepage-carousel-slide {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
  .homepage-carousel-h1 {
    font-size: 3.75rem;
  }
  .homepage-carousel-body {
    font-size: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .homepage-why-line-1, .homepage-why-line-2, .homepage-why-line-3, .homepage-why-line-4, .homepage-why-line-5 {
    left: calc(25% - 45px);
    width: 25%;
  }
  .homepage-why-line-6, .homepage-why-line-7, .homepage-why-line-8, .homepage-why-line-9, .homepage-why-line-10 {
    right: calc(25% - 45px);
    width: 25%;
  }
  .homepage-why-image {
    width: 50%;
  }
}
.checkout-navigation {
  counter-reset: checkout-navigation;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkout-navigation li > span, .checkout-navigation li > a {
  text-decoration: none;
  display: block;
  display: flex;
  align-items: center;
  color: #212529;
  line-height: 1.2;
  font-size: 0.875rem;
}
.checkout-navigation li > span::before, .checkout-navigation li > a::before {
  counter-increment: checkout-navigation;
  content: counter(checkout-navigation);
  margin-right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: 700;
  color: #212529;
  border: 2px solid #212529;
}
.checkout-navigation li.active > span, .checkout-navigation li.active > a {
  color: #C28E40;
}
.checkout-navigation li.active > span::before, .checkout-navigation li.active > a::before {
  color: #C28E40;
  border-color: #C28E40;
}

.shopping-cart-shipping-option .custom-control {
  padding-left: 0;
}
.shopping-cart-shipping-option .custom-control-label {
  display: flex;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  align-items: center;
}
.shopping-cart-shipping-option .custom-control-label::before, .shopping-cart-shipping-option .custom-control-label::after {
  top: auto;
  left: 0;
}
.shopping-cart-shipping-option .custom-control-label .logo {
  width: 1.75rem;
  flex: 0 0 1.75rem;
  margin-right: 0.5rem;
}
.shopping-cart-shipping-option .custom-control-label .title {
  flex-grow: 1;
  padding-right: 1rem;
}
.shopping-cart-shipping-option .custom-control-label .price {
  text-align: right;
}
.shopping-cart-shipping-option + .shopping-cart-shipping-option {
  border-top: 1px solid #dee2e6;
}

.shopping-cart-shipping-option-content {
  padding-left: 5.5rem;
  color: #6c757d;
  font-size: 0.75rem;
  margin-top: -0.5rem;
  padding-bottom: 0.75rem;
}
.shopping-cart-shipping-option-content > *:last-child {
  margin-bottom: 0;
}

.checkout-section + .checkout-section {
  margin-top: 3rem;
}

.checkout-section-h1 {
  margin-bottom: 1rem;
}
.checkout-section-h1 h2 {
  font-size: 1.25rem;
  margin: 0;
}

.product-select-section {
  background: linear-gradient(to bottom, #DFDFDF, #DFDFDF 100px, #fff 100px, #fff);
  padding-top: 0;
  padding-bottom: 0;
}

.product-select-container {
  background: #fff;
  box-shadow: 0 7px 15px -5px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  padding: 30px;
}

.product-select-container-tags {
  display: flex;
  margin-left: -30px;
  margin-bottom: 1rem;
}

.product-select-hr {
  border-top: 1px dashed #999999;
  margin-left: -30px;
  margin-right: -30px;
}

.product-select-info {
  max-width: 560px;
}

@media (min-width: 1200px) {
  .product-select-col {
    flex-grow: 0.75;
  }
}
.news-slider .slider {
  overscroll-behavior-x: contain;
}
.news-slider .news-slider-item {
  width: calc(100% - 3rem);
}
.news-slider .news-slider-item + .news-slider-item {
  margin-left: 2rem;
}

.news-slider-link {
  color: inherit;
}
.news-slider-link:hover {
  text-decoration: none;
}

.news-slider-image {
  border-radius: 0.75rem;
}

.news-slider-tags {
  position: absolute;
  left: 0;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.news-slider-tag {
  color: #fff;
  text-transform: uppercase;
  padding: 0.25rem 1rem;
}

.news-slider-tag-primary {
  background: #648B4F;
}

.news-slider-tag-secondary {
  background: #C28E40;
}

.news-slider-tag-ternary {
  border: 1px solid #C28E40;
  color: #C28E40;
  border-left: 0;
}

.news-slider-controls {
  display: flex;
  justify-content: space-between;
}

.news-slider-control {
  display: inline-flex;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  color: #6c757d;
  cursor: pointer;
}
.news-slider-control:hover {
  color: #648B4F;
  text-decoration: none;
}

.news-tag-link {
  color: inherit;
  display: inline-block;
  border-bottom: 1px solid currentColor;
}
.news-tag-link:hover {
  text-decoration: none;
}

.news-tag-btn {
  display: inline-block;
  color: #fff;
  padding: 0.375rem 0.75rem;
  text-transform: uppercase;
}
.news-tag-btn:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}

@media (min-width: 576px) {
  .news-slider .news-slider-item {
    width: calc(50% - 3rem);
  }
}
@media (min-width: 992px) {
  .news-slider .news-slider-item {
    width: calc(33.3333333333% - 1.5rem);
  }
}
.albums-slider-item {
  width: 50%;
  flex-basis: 50%;
  padding-left: 1px;
  padding-right: 1px;
}
.albums-slider-item a {
  margin-top: 2px;
}

@media (min-width: 576px) {
  .albums-slider-item {
    width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 992px) {
  .albums-slider-item {
    width: 20%;
    flex-basis: 20%;
  }
}
.faq-btn {
  color: #212529;
  border: 1px solid #212529;
  display: flex;
  border-radius: 0.75rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  font-weight: 700;
  text-transform: uppercase;
  min-height: 8rem;
  background: url("/faq_background.webp") no-repeat center center;
  background-size: cover;
  padding: 0.5rem;
}
.faq-btn:hover, .faq-btn.expanding, .faq-btn.expanded {
  text-decoration: none;
  border-color: #BD8933;
  box-shadow: 0 0 0 2px #BD8933;
  color: #212529;
}

.no-webp .faq-btn {
  background-image: url("/faq_background.jpg");
}

.overflow-hidden {
  overflow: hidden;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none;
  }
  .d-md-block {
    display: block;
  }
  .d-md-inline-block {
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-flex {
    display: flex;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-block {
    display: block;
  }
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mt--3 {
  margin-top: -1rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-6 {
  margin-top: 3rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

.mb-form-row {
  margin-bottom: 10px;
}

.ml-auto {
  margin-left: auto;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pt-6 {
  padding-top: 3rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

@media (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-5 {
    margin-bottom: 2rem;
  }
}
@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0;
  }
  .pt-md-0 {
    padding-top: 0;
  }
  .pb-md-0 {
    padding-bottom: 0;
  }
}
@media (min-width: 992px) {
  .mb-lg-0 {
    margin-bottom: 0;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .mb-xl-0 {
    margin-bottom: 0;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem;
  }
}
.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-baseline {
  align-items: baseline;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

@media (min-width: 576px) {
  .order-sm-2 {
    order: 2;
  }
}
@media (min-width: 768px) {
  .order-md-2 {
    order: 2;
  }
}
@media (min-width: 992px) {
  .justify-content-lg-center {
    justify-content: center;
  }
  .order-lg-2 {
    order: 2;
  }
}
@media (min-width: 1200px) {
  .order-xl-2 {
    order: 2;
  }
}
@media (min-width: 1400px) {
  .justify-content-xxl-between {
    justify-content: space-between;
  }
}
.text-primary {
  color: #648B4F;
}

.text-secondary {
  color: #C28E40;
}

.text-success {
  color: #648B4F;
}

.text-danger {
  color: #dc3545;
}

.text-muted {
  color: #6c757d;
}

.text-gray {
  color: #ddd;
}

.text-light {
  color: #fff;
}

.bg-success {
  background: #648B4F;
}

.bg-danger {
  background: #dc3545;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.small {
  font-size: 81.25%;
}

.text-italic {
  font-style: italic;
}

.text-small {
  font-size: 0.8125rem;
}

.text-large {
  font-size: 1.25rem;
}

.text-extra-large {
  font-size: 1.625rem;
}

.font-weight-bold {
  font-weight: 700;
}

.text-transform-none {
  text-transform: none;
}

.text-nowrap {
  white-space: nowrap;
}

.text-wspacing {
  letter-spacing: 0.05em;
}

.font-family-base {
  font-family: "Montserrat", sans-serif;
}

.font-size-base {
  font-size: 1rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-weight-light {
  font-weight: 300;
}

.headings-line-height {
  line-height: 1.2;
}

@media (min-width: 576px) {
  .text-sm-nowrap {
    white-space: nowrap;
  }
}
.position-relative {
  position: relative;
}

.position-sticky-top {
  position: sticky;
  top: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mw-1920px {
  max-width: 1920px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
